import { QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getNormalizedFeatureToggles, NormalizedFeatureToggles } from '@api';

export const featureTogglesQueryKey = {
  server: ['featureToggles'],
  client: ['clientFeatureToggles'],
};

export const prefetchFeatureTogglesQuery = async (clientQuery: QueryClient) => {
  await clientQuery.prefetchQuery({
    queryKey: featureTogglesQueryKey.server,
    queryFn: () => getNormalizedFeatureToggles(),
  });

  return clientQuery.getQueryData<NormalizedFeatureToggles>(featureTogglesQueryKey.server);
};

export const useFeatureTogglesQuery = (options?: UseQueryOptions<NormalizedFeatureToggles>) => {
  return useQuery({
    queryKey: featureTogglesQueryKey.server,
    queryFn: () => getNormalizedFeatureToggles(),
    ...options,
  });
};

// NOTE: use it to fetch fresh feature toggles on client,
// The reason to not use useFeatureTogglesQuery is because it prefetches data
// on server that can be staled because of the page caching
export const useClientFeatureTogglesQuery = (
  options?: UseQueryOptions<NormalizedFeatureToggles>,
) => {
  return useQuery({
    queryKey: featureTogglesQueryKey.client,
    queryFn: () => getNormalizedFeatureToggles(),
    ...options,
  });
};
