import {
  keepPreviousData,
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { deleteBlogLike, getBlogArticle, getBlogCategories, getBlogList, setBlogLike } from '@api';

export interface BlogParams {
  type: 'regular' | 'category' | 'tags';
  value: string;
  sort?: string;
}

export type UpdatingKey = 'blogList' | 'blogArticle' | 'moreInterestingArticles';

export const blogKeys = {
  blogList: (params: BlogParams, isAuth: boolean) => ['blogList', params, params.sort, isAuth],
  blogArticle: (slug: string, isAuth: boolean) => ['blogArticle', slug, isAuth],
  moreInterestingArticles: (slug: string, isAuth: boolean) => [
    'moreInterestingArticles',
    slug,
    isAuth,
  ],
  categories: ['blogCategories'],
};

const DEFAULT_BLOG_LIMIT = 8;

const generateMoreInterestingParams = (slug: string) => ({
  populate: '*',
  filters: {
    slug: {
      $ne: slug,
    },
  },
  sort: ['publishedAt:desc'],
  pagination: {
    pageSize: 10,
  },
});

const generateBlogArticlesParams = ({ type, value, sort }: BlogParams) => {
  if (type === 'regular') {
    return {
      populate: '*',
      sort: [sort || 'publishedAt:desc'],
    };
  }

  return {
    populate: '*',
    filters: {
      [type]: {
        slug: {
          $eq: value,
        },
      },
    },
    sort: [sort || 'publishedAt:desc'],
  };
};

export const prefetchBlogArticleQuery = (
  clientQuery: QueryClient,
  slug: string,
  isAuth: boolean,
) => {
  return clientQuery.prefetchQuery({
    queryKey: blogKeys.blogArticle(slug, isAuth),
    queryFn: () => getBlogArticle(slug, { populate: '*', changeCount: true }),
  });
};

export const useBlogArticleQuery = (slug: string, isAuth: boolean, userId?: string) => {
  return useQuery({
    queryKey: blogKeys.blogArticle(slug, isAuth),
    queryFn: () => getBlogArticle(slug, { populate: '*' }, userId),
    placeholderData: keepPreviousData,
  });
};

export const prefetchMoreInterestingBlogArticleQuery = (
  clientQuery: QueryClient,
  slug: string,
  isAuth: boolean,
) => {
  const params = generateMoreInterestingParams(slug);

  return clientQuery.prefetchQuery({
    queryKey: blogKeys.moreInterestingArticles(slug, isAuth),
    queryFn: () => getBlogList(params),
  });
};

export const useMoreInterestingBlogArticleQuery = (
  slug: string,
  isAuth: boolean,
  userId?: string,
) => {
  const params = generateMoreInterestingParams(slug);

  return useQuery({
    queryKey: blogKeys.moreInterestingArticles(slug, isAuth),
    queryFn: () => getBlogList(params, userId),
    placeholderData: keepPreviousData,
  });
};

export const prefetchBlogListInfiniteQuery = (
  clientQuery: QueryClient,
  params: BlogParams,
  isAuth: boolean,
) => {
  const queryParams = generateBlogArticlesParams(params);

  return clientQuery.prefetchInfiniteQuery({
    queryKey: blogKeys.blogList(params, isAuth),
    queryFn: () =>
      getBlogList({ pagination: { page: 1, pageSize: DEFAULT_BLOG_LIMIT }, ...queryParams }),
    initialPageParam: { nextOffset: 1 },
  });
};

export const useBlogListInfiniteQuery = (params: BlogParams, isAuth: boolean, userId?: string) => {
  const queryParams = generateBlogArticlesParams(params);

  return useInfiniteQuery({
    queryKey: blogKeys.blogList(params, isAuth),
    queryFn: ({ pageParam }) =>
      getBlogList(
        {
          pagination: { page: pageParam?.nextOffset || 1, pageSize: DEFAULT_BLOG_LIMIT },
          ...queryParams,
        },
        userId,
      ),
    initialPageParam: { nextOffset: 1 },
    getNextPageParam: (lastPage, pages) => {
      return !!lastPage?.meta?.pagination &&
        lastPage?.meta?.pagination?.pageCount > lastPage?.meta?.pagination?.page
        ? {
            nextOffset: pages.length + 1,
          }
        : undefined;
    },
    placeholderData: keepPreviousData,
  });
};

export const prefetchBlogCategoriesQuery = (clientQuery: QueryClient) => {
  return clientQuery.prefetchQuery({
    queryKey: blogKeys.categories,
    queryFn: () => getBlogCategories(),
  });
};

export const useBlogCategoriesQuery = () => {
  return useQuery({
    queryKey: blogKeys.categories,
    queryFn: () => getBlogCategories(),
  });
};

export const useSetLikeBlogMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      blogId,
      userId,
      updatingKey,
    }: {
      blogId: number;
      userId: string;
      updatingKey: UpdatingKey;
    }) => {
      const data = await setBlogLike(blogId, userId);

      await queryClient.refetchQueries({ queryKey: [updatingKey] });

      return data;
    },
  });
};

export const useDeleteLikeBlogMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      blogId,
      userId,
      updatingKey,
    }: {
      blogId: number;
      userId: string;
      updatingKey: UpdatingKey;
    }) => {
      const data = await deleteBlogLike(blogId, userId);

      await queryClient.refetchQueries({ queryKey: [updatingKey] });

      return data;
    },
  });
};
