import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  CampainListParams,
  createDeveloperProfile,
  CurrentDeveloperAppList,
  DEFAULT_LIMIT,
  DeserializedUser,
  DeveloperAppList,
  DeveloperLiteApp,
  DeveloperProfile,
  getCampainList,
  getCurrentDeveloperApps,
  getCurrentDeveloperProfile,
  getDeveloperApps,
  getDeveloperProfile,
  getDeveloperTeamMembers,
  getDeveloperTokens,
  getHotOffersFilterList,
  getUserData,
  sendDeveloperProfileEmailVerificationCode,
  updateCurrentDeveloperProfile,
  UpdateDeveloperProfileData,
  verifyDeveloperProfileEmailVerificationCode,
} from '@api';

import { ApiOptions } from '@shared/api/ApiProvider';
import displayErrorToast from '@shared/helpers/displayErrorToast';

import { AUTH_USER_QUERY_KEY } from './keys';
import { setSessionUserQuery } from './session';

export const developerQueryKeys = {
  currentDeveloperProfile: [AUTH_USER_QUERY_KEY, 'currentUserDeveloperInfo'] as const,
  currentDeveloperApps: [AUTH_USER_QUERY_KEY, 'currentUserDeveloperApps'] as const,
  developerProfile: ({
    developerId,
    isCurrentDeveloper,
  }: {
    developerId: string;
    isCurrentDeveloper?: boolean;
  }) =>
    isCurrentDeveloper
      ? developerQueryKeys.currentDeveloperProfile
      : ['developerProfile', developerId],
  developerTeamMembers: (userDeveloperId: string) => ['developerTeamMembers', userDeveloperId],
  developerApps: ({
    developerId,
    isCurrentDeveloper,
  }: {
    developerId: string;
    isCurrentDeveloper?: boolean;
  }) =>
    isCurrentDeveloper ? developerQueryKeys.currentDeveloperApps : ['developerApps', developerId],
  developerTokens: (userDeveloperId: string) => ['developerTokens', userDeveloperId],
  listCampain: (params: CampainListParams) => ['infiniteCampainList', params],
};

export const useCurrentDeveloperProfile = () => {
  return useQuery({
    queryKey: developerQueryKeys.currentDeveloperProfile,
    queryFn: () => getCurrentDeveloperProfile(),
  });
};

export const prefetchCurrentDeveloperProfileQuery = async (
  clientQuery: QueryClient,
  options: ApiOptions<{ headers: { Cookie: string } }>,
) => {
  await clientQuery.prefetchQuery({
    queryKey: developerQueryKeys.currentDeveloperProfile,
    queryFn: () => getCurrentDeveloperProfile(options),
  });

  return clientQuery.getQueryData(developerQueryKeys.currentDeveloperProfile);
};

export const setCurrentDeveloperProfileQueryData = (
  clientQuery: QueryClient,
  developerProfile: DeveloperProfile,
) => {
  clientQuery.setQueryData(developerQueryKeys.currentDeveloperProfile, developerProfile);
};

export const useDeveloperProfileQuery = ({
  developerId,
  isCurrentDeveloper,
}: {
  developerId: string;
  isCurrentDeveloper: boolean;
}) => {
  return useQuery({
    queryKey: developerQueryKeys.developerProfile({ developerId, isCurrentDeveloper }),
    queryFn: () =>
      isCurrentDeveloper ? getCurrentDeveloperProfile() : getDeveloperProfile(developerId),
  });
};

export const prefetchDeveloperProfileQuery = async (
  clientQuery: QueryClient,
  {
    developerId,
    isCurrentDeveloper,
  }: {
    developerId: string;
    isCurrentDeveloper?: boolean;
  },
  options: ApiOptions<{ headers: { Cookie: string } }>,
) => {
  const queryKey = developerQueryKeys.developerProfile({
    developerId,
    isCurrentDeveloper,
  });

  await clientQuery.prefetchQuery({
    queryKey,
    queryFn: () =>
      isCurrentDeveloper
        ? getCurrentDeveloperProfile(options)
        : getDeveloperProfile(developerId, {
            ...options,
          }),
  });
};

export const useDeveloperTeamMembersQuery = (creatorId: string | undefined = '') => {
  return useQuery({
    queryKey: developerQueryKeys.developerTeamMembers(creatorId),
    queryFn: () => getDeveloperTeamMembers({ searchParams: { creator_id: creatorId } }),
    enabled: Boolean(creatorId),
  });
};

export const prefetchDeveloperTeamMembersQuery = (clientQuery: QueryClient, creatorId: string) => {
  return clientQuery.prefetchQuery({
    queryKey: developerQueryKeys.developerTeamMembers(creatorId),
    queryFn: () =>
      getDeveloperTeamMembers({
        searchParams: {
          creator_id: creatorId,
        },
      }),
  });
};

const transformCurrentDeveloperAppListToDeveloperAppList = ({
  total_apps,
  data,
}: CurrentDeveloperAppList): DeveloperAppList => {
  return {
    count: total_apps,
    rows: data as DeveloperLiteApp[],
  };
};

export const useDeveloperAppsQuery = ({
  developerId,
  isCurrentDeveloper,
}: {
  developerId: string;
  isCurrentDeveloper?: boolean;
}) => {
  return useQuery({
    queryKey: developerQueryKeys.developerApps({
      developerId,
      isCurrentDeveloper,
    }),
    queryFn: async () => {
      if (isCurrentDeveloper) {
        const currentDeveloperApps = await getCurrentDeveloperApps();

        return transformCurrentDeveloperAppListToDeveloperAppList(currentDeveloperApps);
      }

      return getDeveloperApps({ searchParams: { creator_id: developerId } });
    },
  });
};

export const prefetchDeveloperAppsQuery = (
  clientQuery: QueryClient,
  {
    developerId,
    isCurrentDeveloper,
  }: {
    developerId: string;
    isCurrentDeveloper?: boolean;
  },
  options: ApiOptions<{ headers: { Cookie: string } }>,
) => {
  return clientQuery.prefetchQuery({
    queryKey: developerQueryKeys.developerApps({ developerId, isCurrentDeveloper }),
    queryFn: async () => {
      if (isCurrentDeveloper) {
        const currentDeveloperApps = await getCurrentDeveloperApps(options);

        return transformCurrentDeveloperAppListToDeveloperAppList(currentDeveloperApps);
      }

      return getDeveloperApps({
        ...options,
        searchParams: {
          creator_id: developerId,
        },
      });
    },
  });
};

export const useDeveloperTokensQuery = (userDeveloperId: string | undefined = '') => {
  return useQuery({
    queryKey: developerQueryKeys.developerTokens(userDeveloperId),
    queryFn: () =>
      getDeveloperTokens({
        searchParams: {
          creator_id: userDeveloperId,
        },
      }),
    enabled: Boolean(userDeveloperId),
  });
};

export const prefetchDeveloperTokensQuery = (
  clientQuery: QueryClient,
  userDeveloperId: string,
  options: ApiOptions<{ headers: { Cookie: string } }>,
) => {
  return clientQuery.prefetchQuery({
    queryKey: developerQueryKeys.developerTokens(userDeveloperId),
    queryFn: () =>
      getDeveloperTokens({
        ...options,
        searchParams: {
          creator_id: userDeveloperId,
        },
      }),
  });
};

export const useUpdateDeveloperProfileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: UpdateDeveloperProfileData) => {
      await updateCurrentDeveloperProfile({ json: data });

      const user = await getUserData();
      const developerProfile = await getCurrentDeveloperProfile();

      return { developerProfile, user };
    },
    onMutate: async (partialData: UpdateDeveloperProfileData) => {
      await queryClient.cancelQueries({ queryKey: developerQueryKeys.currentDeveloperProfile });

      const previousDeveloperProfile = queryClient.getQueryData(
        developerQueryKeys.currentDeveloperProfile,
      );

      queryClient.setQueryData(
        developerQueryKeys.currentDeveloperProfile,
        (old?: DeveloperProfile) => (old ? { ...old, ...partialData } : old),
      );

      return {
        previousDeveloperProfile,
      };
    },
    onSuccess: ({
      developerProfile,
      user,
    }: {
      user: DeserializedUser;
      developerProfile: DeveloperProfile;
    }) => {
      setSessionUserQuery(queryClient, user);
      queryClient.setQueryData(developerQueryKeys.currentDeveloperProfile, developerProfile);
    },
    onError: (err, newTodo, context) => {
      if (context) {
        queryClient.setQueryData(
          developerQueryKeys.currentDeveloperProfile,
          context.previousDeveloperProfile,
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: developerQueryKeys.currentDeveloperProfile });
    },
  });
};

export const useCreateDeveloperProfileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: UpdateDeveloperProfileData) => {
      await createDeveloperProfile({ json: data });

      const user = await getUserData();
      const developerProfile = await getCurrentDeveloperProfile();

      return { developerProfile, user };
    },

    onSuccess: ({
      developerProfile,
      user,
    }: {
      user: DeserializedUser;
      developerProfile: DeveloperProfile;
    }) => {
      setSessionUserQuery(queryClient, user);
      queryClient.setQueryData(developerQueryKeys.currentDeveloperProfile, developerProfile);
    },
  });
};

export const useHotOffersFiltersList = () =>
  useQuery({
    queryKey: developerQueryKeys.currentDeveloperProfile,
    queryFn: () => getHotOffersFilterList(),
    staleTime: 0,
  });

export const useCampainInfiniteQuery = (params: CampainListParams) => {
  return useInfiniteQuery({
    queryKey: developerQueryKeys.listCampain(params),
    queryFn: async ({ pageParam: offset }) => {
      try {
        const response = await getCampainList({
          searchParams: {
            ...params,
            offset,
          },
        });
        return response;
      } catch (err) {
        const error = err as {
          data?: { message: string }[];
        };

        if (Array.isArray(error?.data)) {
          displayErrorToast({ error: error.data[0]?.message });
        }

        throw new Error('Something went wrong');
      }
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) =>
      lastPage.length === DEFAULT_LIMIT ? DEFAULT_LIMIT * pages.length : undefined,
    staleTime: 0,
    gcTime: 0,
  });
};

export const useDeveloperSendVerificationCodeQuery = (
  email: string,
  options?: { enabled?: boolean },
) => {
  return useQuery({
    queryKey: [AUTH_USER_QUERY_KEY, 'sendVerificationCode', email],
    queryFn: () => sendDeveloperProfileEmailVerificationCode({ json: { email } }),
    ...options,
  });
};

export const useDeveloperConfirmVerificationCodeMutation = () => {
  return useMutation({
    mutationFn: async (options: { email: string; code: string }) => {
      return verifyDeveloperProfileEmailVerificationCode({
        json: { ...options, code: Number(options.code) },
      });
    },
  });
};
